import React from 'react';
import {
  Collapse,
  Drawer,
  FormControl,
  FormGroup,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  withStyles
} from "@material-ui/core";
import {Link} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import LocalHospitalRounded from "@material-ui/icons/LocalHospitalRounded";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import {
  AccountCircle, AlarmOn,
  Assessment as AssessmentIcon,
  AssessmentOutlined,
  Category,
  CommentOutlined,
  PeopleAltTwoTone,
  TuneTwoTone
} from "@material-ui/icons";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useHasRole from "../../hooks/user.role.hook";
import {DateRangeIcon} from "@material-ui/pickers/_shared/icons/DateRangeIcon";


const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      background: '#fff'
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  tenantSelectionContainer: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1)
  }
}));

const NavbarDrawer = ({
                        openDrawer,
                        setOpenDrawer,
                        classes,
                        tenants,
                        tenantLocations,
                        selectedTenant,
                        selectedTenantLocation,
                        onTenantChange,
                        onLocationChange,
                        isSystemAdmin,
                        setViewIndex,
                        viewIndex,
                        setCalendarViewIndex,
                        calendarViewIndex,
                        viewReportsItems,
                        setViewReportsItems
                      }) => {
  const {checkPermission} = useHasRole();
  const canTenantRead = checkPermission("TENANT_READ");
  const canMedicationRead = checkPermission("MEDICATION_READ");
  const canReadAssessments = checkPermission('ASSESSMENT_READ');
  const canReadUsers = checkPermission('USER_READ');
  const canReadUserCalendar = checkPermission("USER_CALENDAR_READ");
  const canReadTenantCalendar = checkPermission("TENANT_CALENDAR_READ");
  const isReportViewer = checkPermission("REPORT_VIEWER");
  const isTimeEntryReportViewer = checkPermission("TIME_ENTRY_REPORT_VIEW");
  const classez = useStyles();

  return (
    <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
      <FormGroup className={classez.tenantSelectionContainer}>
      {tenants?.length > 1 && (
        <FormControl>
          <InputLabel><Typography style={{color: '#fff'}} variant={"h6"}>Tenant</Typography></InputLabel>
          <Select
            fullWidth
            id="tenant-location-select"
            onChange={onTenantChange}
            value={
              selectedTenant
                ? selectedTenant._id
                : -1
            }
            input={<BootstrapInput />}
          >
            {true === false && tenants?.length > 1 && (
              <MenuItem value={-1}>All</MenuItem>
            )}
            {tenants?.map((tenant, key) => (
              <MenuItem key={key} value={tenant._id}>
                {tenant.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {tenantLocations?.length > 0 && (
        <FormControl>
          <InputLabel><Typography style={{color: '#fff'}} variant={"h6"}>Location</Typography></InputLabel>
          <Select
            fullWidth
            id="tenant-location-select"
            value={
              selectedTenantLocation
                ? selectedTenantLocation._id
                : -1
            }
            onChange={onLocationChange}
            input={<BootstrapInput />}
          >
            {tenantLocations?.length > 1 && (
              <MenuItem value={-1}>All</MenuItem>
            )}
            {tenantLocations?.sort((a, b) => a?.name > b?.name ? 1: -1)?.map((location, key) => (
              <MenuItem key={key} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      </FormGroup>
      <List className={classes.list}>
        <ListItem
          component={Link}
          divider
          to="/dashboard"
          onClick={() => setOpenDrawer(false)}
        >
          <ListItemIcon>
            <Icon>dashboard</Icon>
          </ListItemIcon>
          <ListItemText primary="Dashboard"></ListItemText>
        </ListItem>
        {(canReadUserCalendar || canReadTenantCalendar) &&
          <ListItem style={{cursor: 'pointer'}}
                    divider
                    onClick={() => setCalendarViewIndex(calendarViewIndex === -1 ? 0 : -1)}>
            <ListItemIcon>
              <DateRangeIcon/>
            </ListItemIcon>
            <ListItemText primary={"Calendars"}/>
            {calendarViewIndex === 0 ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>}
        <Collapse in={calendarViewIndex === 0}>
          {canReadUserCalendar &&
            <ListItem
              divider
              component={Link}
              to="/mycalendar"
              onClick={() => setOpenDrawer(false)}
              className={classes.nested}
            >
              <ListItemIcon>
                <AccountCircle/>
              </ListItemIcon>
              <ListItemText primary="My Calendar"></ListItemText>
            </ListItem>
          }
          {canReadTenantCalendar &&
            <ListItem
              component={Link}
              to="/facilitycalendar"
              divider
              onClick={() => setOpenDrawer(false)}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocationCityIcon></LocationCityIcon>
              </ListItemIcon>
              <ListItemText primary="Facility Calendar"></ListItemText>
            </ListItem>
          }
        </Collapse>
        <ListItem
          component={Link}
          divider
          to="/residents"
          onClick={() => setOpenDrawer(false)}
        >
          <ListItemIcon>
            <Icon>people</Icon>
          </ListItemIcon>
          <ListItemText primary="Residents"></ListItemText>
        </ListItem>
        <ListItem style={{cursor: 'pointer'}} divider onClick={() => setViewIndex(viewIndex === -1 ? 0 : -1)}>
          <ListItemAvatar>
            <TuneTwoTone/>
          </ListItemAvatar>
          <ListItemText primary={"Administration"}/>
          {viewIndex === 0 ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={viewIndex === 0}>
          {canReadAssessments &&
            <ListItem divider button
                      component={Link}
                      to={`/assessments`}
                      onClick={() => setOpenDrawer(false)}
                      className={classes.nested}>
              <ListItemAvatar>
                <AssessmentIcon/>
              </ListItemAvatar>
              <ListItemText primary={"Assessments"}/>
            </ListItem>
          }
          <ListItem component={Link}
                    to={"/allergies"}
                    onClick={() => setOpenDrawer(false)}
                    divider
                    className={classes.nested}>
            <ListItemIcon>
              <i className="fas fa-2x fa-allergies"></i>
            </ListItemIcon>
            <ListItemText primary={"Allergies"}/>
          </ListItem>
          <ListItem component={Link} to={"/diagnosis"}
                    onClick={() => setOpenDrawer(false)}
                    divider
                    className={classes.nested}>
            <ListItemIcon>
              <i className="fas fa-2x fa-clipboard-list"></i>
            </ListItemIcon>
            <ListItemText primary={"Diagnosis"}/>
          </ListItem>
          {canMedicationRead && <ListItem
            component={Link}
            divider
            to="/medications"
            onClick={() => setOpenDrawer(false)}
            className={classes.nested}
          >
            <ListItemIcon>
              <LocalHospitalRounded/>
            </ListItemIcon>
            <ListItemText primary="Medications"></ListItemText>
          </ListItem>}
          <ListItem component={Link} to={"/notecategories"}
                    onClick={() => setOpenDrawer(false)}
                    divider
                    className={classes.nested}>
            <ListItemIcon>
              <Category/>
            </ListItemIcon>
            <ListItemText primary={"Note Categories"}/>
          </ListItem>
          {isSystemAdmin() && (
            <ListItem component={Link} to="/permissions"
                      onClick={() => setOpenDrawer(false)}
                      divider
                      className={classes.nested}>
              <ListItemIcon>
                <i class="fas fa-2x fa-user-cog"></i>
              </ListItemIcon>
              <ListItemText primary="Permissions"/>
            </ListItem>
          )}
          {selectedTenant?.features?.timeClock && isTimeEntryReportViewer &&
              <ListItem component={Link}
                        to={"/timeentry"}
                        divider
                        className={classes.nested}
                        onClick={() => setOpenDrawer(false)}
              >
                <ListItemIcon>
                  <AlarmOn />
                </ListItemIcon><ListItemText primary={"Time Entry"} />
              </ListItem>
          }
          {isReportViewer &&
            <>
              <ListItem divider
                        button
                        onClick={() => setViewReportsItems(viewReportsItems === -1 ? 0 : -1)}
                        className={classes.nested}
              >
                <ListItemText primary={"Reports"}/>
                {viewReportsItems === 0 ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={viewReportsItems === 0}>
                <ListItem component={Link}
                          to={"/notes"}
                          divider
                          className={classes.nested3rd}
                          onClick={() => setOpenDrawer(false)}
                >
                  <ListItemIcon>
                    <CommentOutlined/>
                  </ListItemIcon>
                  <ListItemText primary={"Notes"}/>
                </ListItem>
                <ListItem component={Link}
                          to={"/reports"}
                          divider
                          className={classes.nested3rd}
                          onClick={() => setOpenDrawer(false)}>
                  <ListItemIcon>
                    <AssessmentOutlined/>
                  </ListItemIcon>
                  <ListItemText primary={"Report Center"}/>
                </ListItem>
              </Collapse>
            </>
          }
          {canTenantRead && <ListItem
            component={Link}
            divider
            to="/tenants"
            onClick={() => setOpenDrawer(false)}
            className={classes.nested}
          >
            <ListItemIcon>
              <LocationCityIcon></LocationCityIcon>
            </ListItemIcon>
            <ListItemText primary="Tenant"></ListItemText>
          </ListItem>}
          {canReadUsers &&
            <ListItem divider
                      button
                      component={Link}
                      to={'/users'}
                      onClick={() => setOpenDrawer(false)}
                      className={classes.nested}>
              <ListItemAvatar>
                <PeopleAltTwoTone/>
              </ListItemAvatar>
              <ListItemText primary={"Users"}/>
            </ListItem>}
        </Collapse>
      </List>
    </Drawer>
  )
}

export default NavbarDrawer;
